<template>
  <div class="container">
    <h2 class="title" :data-content="title" v-html="title"></h2>
    <p>
      {{ detail }}
    </p>
    <div class="hero-image-area" v-if="image?.length">
      <img :src="image" class="hero-image" alt="program process img 1" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroSection",
  props: {
    title: {
      type: String,
      default: "",
    },
    detail: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 101;
  padding-bottom: 10rem;
  p {
    width: 60%;
  }

  .hero-image-area {
    position: absolute;
    bottom: 0;
    width: 200%;
    height: 40%;
    overflow: hidden;
    // background-color: red;
    clip-path: ellipse(30% 100% at 50% 0%);
    display: flex;
    justify-content: center;
    .hero-image {
      height: 100%;
      width: auto;
    }
  }

  @media (max-width: 900px) {
    p {
      width: 100%;
      margin-bottom: 5rem;
    }
    .hero-image-area {
      clip-path: ellipse(55% 100% at 50% 0%);
    }
  }
}
</style>
