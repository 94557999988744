<template>
  <div class="container">
    <h2 class="title-secondary" :data-content="title">
      {{ title }}
    </h2>
    <div class="story" v-for="(item, i) in items" :key="i">
      <h3
        class="title-secondary mt-3"
        :data-content="item.label"
        v-html="item.label"
      ></h3>
      <p v-html="item.detail"></p>
      <img
        class="story-img"
        :src="item.image"
        :class="{
          'story-img-right': !isEven(i),
          'story-img-left': isEven(i),
        }"
        v-show="item.image"
        alt="the about img 1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "StorySection",
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: [Array, null],
      default: null,
    },
  },
  methods: {
    isEven(num) {
      return num % 2 == 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .story {
    position: relative;
    width: 70%;

    .title-secondary {
      width: 100%;
    }
    .story-img {
      position: absolute;
      width: 10rem;
      top: 25%;
      z-index: 101;

      &.story-img-right {
        right: -35%;
      }
      &.story-img-left {
        left: -37%;
      }
    }
  }
  @media (max-width: 800px) {
    width: 75vw;
    .story {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      width: 100%;
      gap: 1rem;
      .title,
      .title-secondary {
        font-size: 2.5rem;
        order: 1;
        width: 100%;
      }
      .story-img {
        display: block;
        position: unset;
      }
    }
  }
}
</style>
